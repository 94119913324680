import { VehicleStatus } from "static/js/app/models/__index";
import { Availability } from "static/js/app/models/__index";

export class GetModelsOptions {
    constructor(
        public vehicleStatus: VehicleStatus = "used",
        public vehicleType: string = "",
        public manufacturerName: string,
        public availability: Availability = "available",
        
    ) { }
}