import { Vehicle } from "static/js/app/models/vehicle";
import VehicleStringFormatting from "static/js/app/utils/stringFormatting/vehicleStringFormatting";

export class CompareResultsTemplateViewModel
{
    constructor(
        public id: string,
        public price: string = "",
        public mileage: string = "",
        public mainImagePath: string = "/awaitingimage.jpg",
        public description: string = "",
        public vehicleDetailsUrl: string = "",
        public manufacturer: string = "",
        public model: string = "",
        public derivative: string = "",
        public year: string = "",
        public engine: string = "",
        public engineCapacity: string = "",
        public bhp: string = "",
        public gearbox: string = "",
        public mpg: string = "",
        public insuranceGroup: string = "",
        public colour: string = ""
    ) { }

    public static FromVehicle(vehicle: Vehicle): CompareResultsTemplateViewModel {
        if(vehicle == null) {
            throw new Error("vehicle cannot be null")
        }

        const price = VehicleStringFormatting.price(vehicle.price, vehicle.sold);

        const description = this.coerceVehicleDescription(vehicle.description);

        return new CompareResultsTemplateViewModel(
            vehicle.id,
            price,
            this.mileageToString(vehicle.mileage),
            vehicle.main_image?.i800x600 ?? "/awaitingimage.jpg",
            description,
            vehicle.urlPath ?? "",
            vehicle.manufacturer_display ?? "",
            vehicle.model_display ?? "",
            vehicle.derivative_display ?? "",
            vehicle.year_built?.toString() ?? "",
            vehicle.engine ?? "",
            vehicle.engine_capacity?.toString() ?? "",
            vehicle.bhp?.toString() ?? "",
            vehicle.gearbox ?? "",
            vehicle.mpg?.toString() ?? "",
            vehicle.insurance_group ?? "",
            vehicle.colour ?? ""
        );
    }
    private static coerceNullishOrNone(str: string) {
        return (str != null && str != "None") ? str : ""
    }

    private static coerceVehicleDescription(description: string | undefined | null) {
        return this.truncate(this.coerceNullishOrNone(description), 250);
    }

    private static truncate(str: string, maxLen: number): string {
        return (str.length > maxLen - 3)
            ? str.substring(0, maxLen) + "..."
            : str;
    }

    private static mileageToString(n: number | undefined | null): string {
        return (n != null) 
            ? this.numberWithCommas(n)
            : "";
    }

    private static numberWithCommas(n: number) {
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}