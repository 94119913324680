import * as asyncUtil from "static/js/app/utils/asyncUtil";
import * as snSvrApi from "static/js/app/api/snSvrApi";
import * as DtoMapping from "static/js/app/api/dtoMapping";
import * as Models from "static/js/app/models/__index";
import { FinanceSearchTerms } from "static/js/app/models/__index";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";


export async function getVehicleTypes(getVehicleTypesOptions: Models.GetVehicleTypesOptions = new Models.GetVehicleTypesOptions(), callGroupOptions?: Models.CallGroupOptions): Promise<Models.VehicleCount[]> {
    return await asyncUtil.addCancelation<Models.VehicleCount[]>(
        snSvrApi.buildGetArray(
            `vehicles/search/vehicleTypes?availability=${getVehicleTypesOptions.availability}&vehicleStatus=${getVehicleTypesOptions.vehicleStatus}`,
            DtoMapping.VehicleCountDtoToVehicleCount
        ), 
        callGroupOptions)();
}

export async function getSimilarVehicles(vehicleId: number,financeTerms?: FinanceSearchTerms, callGroupOptions?: Models.CallGroupOptions): Promise<Models.Vehicle[]> {
    try {
        const financeTermsQuery = (financeTerms == null ? '' : `?term=${financeTerms.term}&annual_mileage=${financeTerms.annual_mileage}&percentage_deposit=${financeTerms.percentage_deposit}&deposit_amount=${financeTerms.deposit_amount}`);
        return await asyncUtil.addCancelation<Models.Vehicle[]>(
            snSvrApi.buildGetArray(
                `vehicles/stock/similar/${vehicleId}${financeTermsQuery}`,
                DtoMapping.VehicleDtoToVehicle
            ), 
            callGroupOptions)();
    } catch {
        return [];
    }    
}

export async function getMakes(getMakesOptions: Models.GetMakesOptions = new Models.GetMakesOptions(), callGroupOptions?: Models.CallGroupOptions): Promise<Models.VehicleCount[]> {
    return await asyncUtil.addCancelation<Models.VehicleCount[]>(
        snSvrApi.buildGetArray(
            `vehicles/search/manufacturers?vehicleType=${getMakesOptions.vehicleType}&availability=${getMakesOptions.availability}&vehicleStatus=${getMakesOptions.vehicleStatus}`,
            DtoMapping.VehicleCountDtoToVehicleCount
        ), 
        callGroupOptions)();
}

export async function getModels(getModelsOptions: Models.GetModelsOptions, callGroupOptions?: Models.CallGroupOptions): Promise<Models.VehicleCount[]> {
    const vehicleCounts = await asyncUtil.addCancelation<Models.VehicleCount[]>(
        snSvrApi.buildGetArray(
            `vehicles/search/manufacturerModels?vehicleStatus=${getModelsOptions.vehicleStatus}&vehicleType=${getModelsOptions.vehicleType}&manufacturer=${getModelsOptions.manufacturerName}&availability=${getModelsOptions.availability}`,
            DtoMapping.VehicleCountDtoToVehicleCount
        ), 
        callGroupOptions)()
    
    const vehTypeSingular = getModelsOptions.vehicleType.substr(0, getModelsOptions.vehicleType.length - 1);
    return vehicleCounts.map(vc => new Models.VehicleCount(vc.count, undefined, vehTypeSingular, undefined, getModelsOptions.manufacturerName, vc.modelName, vc.modelValue));
}

export async function getSearchTerms(getSearchTermsOptions: Models.GetSearchTermsOptions = new Models.GetSearchTermsOptions(), callGroupOptions?: Models.CallGroupOptions): Promise<Models.SearchTermSpec[]> {
    return await asyncUtil.addCancelation<Models.SearchTermSpec[]>(
        snSvrApi.buildGetArray(
            `vehicles/search/terms?vehicleType=${getSearchTermsOptions.vehicleType}&availability=${getSearchTermsOptions.availability}&vehicleStatus=${getSearchTermsOptions.vehicleStatus}`,
            DtoMapping.SearchTermSpecDtoToSearchTermSpec
        ), 
        callGroupOptions)();
}

export async function getSortableFields(callGroupOptions?: Models.CallGroupOptions): Promise<string[]> {
    return await asyncUtil.addCancelation<string[]>(
        snSvrApi.buildGetArray<string, string>(
            `vehicles/search/sortableFields`,
            (dto) => dto
        ), 
        callGroupOptions)();
}

export async function get(id: string, financeTerms?: FinanceSearchTerms, callGroupOptions?: Models.CallGroupOptions): Promise<Models.Vehicle> {
    const financeTermsQuery = (financeTerms == null ? '' : `?term=${financeTerms.term}&annual_mileage=${financeTerms.annual_mileage}&percentage_deposit=${financeTerms.percentage_deposit}&deposit_amount=${financeTerms.deposit_amount}`);
    return await asyncUtil.addCancelation<Models.Vehicle>(
       
        snSvrApi.buildGetJson(
            `vehicles/stock/${id}${financeTermsQuery}`,
            DtoMapping.VehicleDtoToVehicle
        ), 
        callGroupOptions)();
}

export async function getMonthlyPrice(id: string, financeTerms?: FinanceSearchTerms, callGroupOptions?: Models.CallGroupOptions): Promise<Number> {
    const financeTermsQuery = (financeTerms == null ? '' : `?term=${financeTerms.term}&annual_mileage=${financeTerms.annual_mileage}&percentage_deposit=${financeTerms.percentage_deposit}&deposit_amount=${financeTerms.deposit_amount}`);
    return await asyncUtil.addCancelation<Number>(
       
        snSvrApi.buildGetJson(
            `vehicles/stock/monthlyPrice/${id}${financeTermsQuery}`, (x: Number)=> {return x;}           
        ), 
        callGroupOptions)();
}

export async function fetchMonthlyPrices(monthlyPricesRequest: Models.MonthlyPricesRequest, callGroupOptions?: Models.CallGroupOptions): Promise<Models.MonthlyPriceResult[]> {
    
    return await asyncUtil.addCancelation<Models.MonthlyPriceResult[]>(
       
        snSvrApi.buildPostJson(
            `vehicles/stock/monthlyPrices`,
            monthlyPricesRequest,
            DtoMapping.MonthlyPriceResultDtoArrayToMonthlyPriceResultArray  
        ), 
        callGroupOptions)();
}

export async function search(searchCriteria: Models.SearchCriteria, callGroupOptions?: Models.CallGroupOptions): Promise<Models.VehicleSearchResult> {
    try {
        return await asyncUtil.addCancelation<Models.VehicleSearchResult>(
            snSvrApi.buildPostJson(
                `vehicles/search/stock`, 
                searchCriteria,
                DtoMapping.VehicleSearchResultDtoToVehicleSearchResult
            ), 
            callGroupOptions)();
    } catch (err) {
        console.error(err);
        
        return new Models.VehicleSearchResult(0, 0, 0, [], 0,null);
    }    
}

export async function compare(ids: string[], callGroupOptions?: Models.CallGroupOptions): Promise<Models.Vehicle[]> {
    if(ids === undefined || ids === null || ids.length === 0) {
        return [];
    }

    var idsQsVar = ids.map((id,idx) => `ids[${idx}]=${id}`).join('&');
    return await asyncUtil.addCancelation<Models.Vehicle[]>(
        snSvrApi.buildGetArray(
            `vehicles/stock/compare?${idsQsVar}`,
            DtoMapping.VehicleDtoToVehicle
        ), 
        callGroupOptions)();
}
