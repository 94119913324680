import { HugoMenuItem } from "static/js/app/hugoSettings/hugoSiteConfig";

export class MenuItem {
    constructor(
        public identifier: string|null = null,
        public name: string|null = null,
        public url: string|null = null,
        public weight: number|null = null,
        public parent: string|null = null,
        public children: MenuItem[] = null
    ) {}

    public static FromHugoMenuItem(
        hugoMenuItem: HugoMenuItem
    ): MenuItem {
        if(hugoMenuItem == null) {
            return new MenuItem();// use defaults
        }
        
        return new MenuItem(
            hugoMenuItem.Identifier,
            hugoMenuItem.Name,
            hugoMenuItem.ConfiguredURL,
            (hugoMenuItem.Weight != null) ? parseInt(hugoMenuItem.Weight) : null,
            hugoMenuItem.Parent,
            (hugoMenuItem.Children != null) ? hugoMenuItem.Children.map(MenuItem.FromHugoMenuItem) : null
        );
    }

    public isOptional() {
        const findOptionalStr = "optional-";

        return (this.identifier != null && this.identifier.substr(0,findOptionalStr.length) == findOptionalStr);
    }

    public isStockSearch() {
        return (
            this.isStockSearch_() ||
            this.isSoldStockSearch() ||
            this.isBranchStockSearch() ||
            this.isWebsectionStockSearch()
        );
    }

    private isStockSearch_ = () => 
        this.matchBasePath(this.url, "/used/");

    public isWebsectionStockSearch = () => 
        this.matchBasePath(this.url, "/vehicles/");

    public isBranchStockSearch = () => 
        this.matchBasePath(this.url, "/branch/");

    public isSoldStockSearch = () => 
        this.matchBasePath(this.url, "/sold-gallery/");

    private matchBasePath(url: string, basePath: string) {
        return (url.substr(0, basePath.length) == basePath);
    }
}