export class Guid {
    constructor(
        private guidString: string = Guid.buildGuidString()
    ) { }

    public toString(): string {
        return this.guidString;
    }

    private static buildGuidString(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static create(): Guid {
        return new Guid();
    }
}