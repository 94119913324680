import { HugoSearchConfig, HugoAdvancedSearchConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import { VehicleStatus, VehicleStatusFromString } from "static/js/app/models/__index";

export class SearchConfig {
    constructor(
        public items_per_page: number = 10,
        public sort_by: string = "price",
        public sort_order: string = "desc",
        public dom_element_id: string = "searchBox",
        public search_price_increment: number = 1000,
        public search_button_text: string = "Search",
        public price_dropdown_default: string = "full price",
        public monthly_price_increment: number = 100,
        public isFinanceSearch?: boolean,
        public advanced?: HugoAdvancedSearchConfig,
        public vehicle_statuses: VehicleStatus[] = ["used"],
        public default_vehicle_status: VehicleStatus = "used",
        public enable_previous_next_buttons: boolean = false
    ) {}

    public static FromHugoSearchConfig(
        hugoSearchConfig: HugoSearchConfig
    ): SearchConfig {
        if(hugoSearchConfig == null) {
            return new SearchConfig();// use defaults
        }
        
        return new SearchConfig(
            hugoSearchConfig.items_per_page,
            hugoSearchConfig.sort_by,
            hugoSearchConfig.sort_order,
            hugoSearchConfig.dom_element_id,
            hugoSearchConfig.search_price_increment,
            hugoSearchConfig.search_button_text,
            hugoSearchConfig.price_dropdown_default,
            hugoSearchConfig.monthly_price_increment,
            hugoSearchConfig.isFinanceSearch,
            hugoSearchConfig.advanced,
            hugoSearchConfig?.advanced?.vehicle_statuses?.map(vs=> VehicleStatusFromString(vs)) ?? ["used"],
            VehicleStatusFromString(hugoSearchConfig?.advanced?.default_vehicle_status) ?? "used",
            hugoSearchConfig.enable_previous_next_buttons ?? false
        );
    }
}