import { QuickReplace } from "static/js/app/utils/QuickReplace";
import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import Common from "static/js/app/pages/common";
import DOMPurify from "dompurify";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { CompareResultsTemplateViewModel } from "static/js/app/viewModels/compareResultsTemplateViewModel";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";

export default class ComparisonPage
{
    public static async init(siteConfig: SiteConfig, onTemplatesLoaded: () => void = null) {
        const comparisonResults = await VehicleComparison.compareVehicles();
        const vehicleVms = comparisonResults.map(v => CompareResultsTemplateViewModel.FromVehicle(v));

        Page.ready(function () {
            ComparisonPage.generateComparisonResultsHtml(vehicleVms, onTemplatesLoaded);
        });
    }

    private static generateComparisonResultsHtml (comparisonResults: CompareResultsTemplateViewModel[], onTemplatesLoaded: () => void = null) {
        // Cache of the template
        var template = document.getElementById("compareResultsTemplate");

        // Get the contents of the template
        var templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        var listHtml = "";

        // regex creation is reasonably expensive, cache the regex built for repeated templates like this
        var cachedRegEx: RegExp = null;
        var regExCacher = function (regex: RegExp) {
            // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
            if (regex !== null) {
                cachedRegEx = regex;
            }
            return cachedRegEx;
        };

        // Loop through comparisonResults, replace placeholder tags
        // with actual data, and generate final HTML
        for (var i = 0; i < comparisonResults.length; i++) {
            var vehicle = comparisonResults[i];

            // string replacements to perform
            var replacements = {
                "%vehicle_id%": vehicle.id,
                "%vehicle_year%": StringFormatting.coerceNullishToBlank(vehicle.year),
                "%vehicle_make_display%": vehicle.manufacturer,
                "%vehicle_model_display%": vehicle.model,
                "%vehicle_derivative_display%": StringFormatting.coerceNullishToBlank(vehicle.derivative),
                "%vehicle_price%": vehicle.price,
                "%vehicle_photo%": vehicle.mainImagePath,
                "%vehicle_mileage%": StringFormatting.coerceNullishToBlank(vehicle.mileage),
                "%vehicle_engine%": StringFormatting.coerceNullishToBlank(vehicle.engine),
                "%vehicle_gearbox%": StringFormatting.coerceNullishToBlank(vehicle.gearbox),
                "%vehicle_capacity%": StringFormatting.coerceNullishToBlank(vehicle.engineCapacity),
                "%vehicle_insurance%": StringFormatting.coerceNullishToBlank(vehicle.insuranceGroup),
                "%vehicle_mpg%": StringFormatting.coerceNullishToBlank(vehicle.mpg),
                "%vehicle_colour%": StringFormatting.coerceNullishToBlank(vehicle.colour),
                "%vehicle_bhp%": StringFormatting.coerceNullishToBlank(vehicle.bhp),
                "%vehicle_details_url%": vehicle.vehicleDetailsUrl
            };

            // do replacements (using regex caching)
            listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
        }

        // Replace the HTML of #comparisonResults with final HTML
        document.getElementById("compareResults").innerHTML = DOMPurify.sanitize(listHtml);

        if(onTemplatesLoaded != null) {
            onTemplatesLoaded();
        }

        $(".removeall-button").on("click", (evt) => {
            evt.preventDefault();
            const comparisonVehicleCount = VehicleComparison.getVehiclesCount();
            VehicleComparison.clearComparisonList();

            Common.updateSavedVehiclesCount();

            // update compare slick slider to remove the vehicle
            for(let slideIndex = 0; slideIndex < comparisonVehicleCount; slideIndex++) {
                $('.slick.slick--compare-vehicles').slick('slickRemove', 0);
            }
        } );

        if ($('.slick.slick--compare-vehicles').length > 0) {
          $('.slick.slick--compare-vehicles').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: "<span class='slick-prev main compare'>&lt;</span>",
            nextArrow: "<span class='slick-next main compare'>&gt;</span>",
            infinite: false,
            mobileFirst: true,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 3,
                  autoplay: false
                }
              },
              {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 4,
                  autoplay: false
                }
              }
            ]
          });
      }

      $(".remove-button .button").on("click", (evt) => {
          evt.preventDefault();
          const error = VehicleComparison.toggleVehicleCompare(evt.target.getAttribute("data-vehId"));
          if(error != null) {
              console.log(error);
          }

          Common.updateSavedVehiclesCount();

          // update compare slick slider to remove the vehicle
          const slideLi = evt.target.closest('li.slick-slide');
          const slideIndex = slideLi.getAttribute('data-slick-index');
          $('.slick.slick--compare-vehicles').slick('slickRemove', parseInt(slideIndex));

          // update slick indexes - they're all wrong - looks like a bug in slick (https://github.com/kenwheeler/slick/issues/1406)
          $('.slick.slick--compare-vehicles .slick-slide').each((i, el) => {
            el.setAttribute('data-slick-index', i.toString());
          });
      } );
    }
}
