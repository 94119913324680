export class StickyElement {   
    constructor(stickyElement: JQuery<HTMLElement>, stickyWatchElement: JQuery<HTMLElement>) {
        $(window).scroll(() => {
            var window_top = $(window).scrollTop();

            var top = stickyWatchElement.offset().top;
            if (window_top > top) {
                stickyElement.addClass('stick');
            } else {
                stickyElement.removeClass('stick');
            }
        });
    }
}
