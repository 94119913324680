import { HugoFinanceConfig } from "themes/module_finance_plugin/static/js/hugoFinanceConfig";

export class FinanceConfig {
    constructor(
        public provider: string = "",
        public api_key_username: string = "",
        public default_term: number = 0,
        public default_deposit_amount: number = 0,
        public default_deposit_percentage: number = 0,
        public default_mileage: number = 0,
        public finance_cta: string = "none",
        public finance_checker: string = "none",
        public reserve_button_type: string = "none"

    ) {}

    public static FromHugoFinanceConfig(
        hugoFinanceConfig: HugoFinanceConfig
    ): FinanceConfig {
        if(hugoFinanceConfig == null) {
            return new FinanceConfig();// use defaults
        }
        
        return new FinanceConfig(
            hugoFinanceConfig.provider,
            hugoFinanceConfig.api_key_username,
            hugoFinanceConfig.default_term,
            hugoFinanceConfig.default_deposit_amount,
            hugoFinanceConfig.default_deposit_percentage,
            hugoFinanceConfig.default_mileage,
            hugoFinanceConfig.finance_cta,
            hugoFinanceConfig.finance_checker,
            hugoFinanceConfig.reserve_button_type

        );
    }
}