import { HugoMenuTopConfig } from "static/js/app/hugoSettings/hugoSiteConfig";

export default class MenuTop {
    public static init(config: MenuTopConfig) {
        if(config.hideLogoWhenExpanded)
        {
            MenuTop.hideLogoWhenExpanded();
        }
    }

    private static hideLogoWhenExpanded() {
        // ******
            //  Function to hide logo when menu is expanded on mobile/tablet
            // ******
            // Make sure the top bar is there
            if ($('.top-bar.expanded').length) {
                // TODO: DOES THIS EVEN WORK? Doesn't seem to for me. If not, it should be either fixed or removed!
                // If the menu icon is clicked
                $('.menu-icon a').on("click", function () {

                    // Theres a small delay in the class we need to use being added so we set a time out
                    setTimeout(function () {
                        // if the top bar has the expanded class
                        if ($('.top-bar').hasClass('expanded')) {
                            // hide the logo and set nav to relative to pass content down
                            $('.l-header__logo').css({ 'height': '0', 'opacity': '0' });
                            $('.l-header__nav').css('position', 'relative');
                        } else {
                            // else undo changes
                            $('.l-header__nav').css('position', 'absolute');
                            $('.l-header__logo').css({ 'height': 'auto', 'opacity': '1' });
                        }
                    }, 0.1);

                });
            }
    }
}

export class MenuTopConfig {
    constructor(
        public hideLogoWhenExpanded: boolean = false
    ) {}

    public static FromHugoMenuTopConfig(
        hugoMenuTopConfig: HugoMenuTopConfig
    ): MenuTopConfig {
        if(hugoMenuTopConfig == null) {
            return new MenuTopConfig();// use defaults
        }
        
        return new MenuTopConfig(
            hugoMenuTopConfig.hide_logo_when_expanded
        );
    }
}