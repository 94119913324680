import { HugoModuleSimilarVehiclesSliderConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesSliderHugoConfig";

export class ModuleSimilarVehiclesConfig {
    public constructor(
        public domElementId = "similarVehiclesSlider",
        public domTemplateElementId = "SimilarVehiclesTemplate",
        public showSectionHeader = true,
        public imageHeaderHtmlTemplate = "%vehicle_make_display% %vehicle_model_display%",
        public showPrice = true,
        public numberOfItemsToDisplay = 20,
        public imageSize = "i320x240",
        public slickSettings = "{}",
        public includeOnHomepage = false
    ) {}

    public static FromHugoModuleSimilarVehiclesConfig(
        hugoSimilarVehiclesConfig: HugoModuleSimilarVehiclesSliderConfig
    ): ModuleSimilarVehiclesConfig {
        if(hugoSimilarVehiclesConfig == null) {
            return null;
        }

        return new ModuleSimilarVehiclesConfig(
            hugoSimilarVehiclesConfig.dom_element_id,
            hugoSimilarVehiclesConfig.dom_template_element_id,
            hugoSimilarVehiclesConfig.show_section_header,
            hugoSimilarVehiclesConfig.image_header_html_template,
            hugoSimilarVehiclesConfig.show_price,
            hugoSimilarVehiclesConfig.number_of_items_to_display,
            hugoSimilarVehiclesConfig.image_size,
            hugoSimilarVehiclesConfig.slick_settings,
            true
        );
    }
}