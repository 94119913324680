import { FinanceQuote, FinanceSearchTerms, Vehicle } from "static/js/app/models/__index";
import { buildVehicleFinanceQuotes } from "themes/module_finance_plugin/static/js/finance_quotes_template";
import { buildVehicleMonthlyPayment } from "themes/module_finance_plugin/static/js/finance_monthly_payment_template";
import { Page } from "static/js/app/modules/page";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { LocalCache } from "static/js/app/utils/localStorageHelper";



export default class VehicleFinanceQuotes {
	public static setVehicleFinanceQuotes(quotes: FinanceQuote[]): string {
		//const showFinanceQuotes = $('.l-node-used-vehicle--search-result__finance-results').length;
		//console.log(`show finance ${showFinanceQuotes}`);
		if ( quotes == null || quotes.length == 0) {
			return '';
		}
		return buildVehicleFinanceQuotes(quotes);
	}

	public static setVehicleMonthlyPayment(vehicle: Vehicle)
	{
		if (vehicle == null)
		{
			return '';
		}
		return buildVehicleMonthlyPayment(vehicle);
	}

	public static setFinanceSearchTerms(financeSearchTerms: FinanceSearchTerms)
	{
		if (financeSearchTerms == null)
		{
			return;
		}
		let searchFiltersEl = $('#financeSearchFilters');
        let financeTermEl: JQuery<HTMLElement> = searchFiltersEl.find('select[name="finance-term"] option');
		let financeDepositPercentage: JQuery<HTMLElement> = searchFiltersEl.find('select[name="finance-deposit-percentage"] option');
		let financeDepositAmount: JQuery<HTMLElement> = searchFiltersEl.find('select[name="finance-deposit-amount"] option');
		let financeAnnualMileage: JQuery<HTMLElement> = searchFiltersEl.find('select[name="finance-annual-mileage"] option');
		
		LocalCache.setWithExpiry("finance-term", financeSearchTerms.term.toString(), );
		LocalCache.setWithExpiry("finance-deposit-percentage", (financeDepositPercentage.length > 0 ? financeSearchTerms.percentage_deposit ?? 0 : 0).toString());
		LocalCache.setWithExpiry("finance-deposit-amount", (financeDepositAmount.length > 0  ? financeSearchTerms.deposit_amount ?? 0 : 0).toString());				
		LocalCache.setWithExpiry("finance-annual-mileage", financeSearchTerms.annual_mileage.toString());

        var optionsAndSelectedValues = [
            { options: financeTermEl, selectedValue: financeSearchTerms.term.toString() },
            { options: financeDepositPercentage, selectedValue: financeSearchTerms.percentage_deposit.toString() },
			{ options: financeDepositAmount, selectedValue: financeSearchTerms.deposit_amount.toString() },
			{ options: financeAnnualMileage, selectedValue: financeSearchTerms.annual_mileage.toString() },
        ];

        for (var i = 0; i < optionsAndSelectedValues.length; i++) {
            var options = optionsAndSelectedValues[i].options;
            var selectedValue = optionsAndSelectedValues[i].selectedValue;

            if (options.length> 0 && selectedValue != undefined && selectedValue !== null) {
                for (var oi = 0; oi < options.length; oi++) {
                    let optionEl = options[oi] as HTMLOptionElement;
                    if (selectedValue == optionEl.value) {
                        optionEl.selected = true;
                    }
                }
            }
        }
	}

	public static getFinanceSearchTerms( financeConfig: FinanceConfig ): FinanceSearchTerms
	{
		if (financeConfig == null || financeConfig.provider === 'none')
		{
			return null;
		}
		let financeTerm = parseInt(Page.queryString["finance-term"] ?? LocalCache.getWithExpiry("finance-term")) || 0;
		let annualMileage = parseInt(Page.queryString["finance-annual-mileage"] ?? LocalCache.getWithExpiry("finance-annual-mileage")) || 0;
		let depositAmount = parseInt(Page.queryString["finance-deposit-amount"] ?? LocalCache.getWithExpiry("finance-deposit-amount")) || 0;
		let percentageDeposit =  parseInt(Page.queryString["finance-deposit-percentage"] ?? LocalCache.getWithExpiry("finance-deposit-percentage")) || 0;
		let isDefault = (financeTerm == financeConfig.default_term || financeTerm ==0) 
		&& (annualMileage == financeConfig.default_mileage || annualMileage ==0) 
		&& (depositAmount == financeConfig.default_deposit_amount || depositAmount == 0)
		&& (percentageDeposit == financeConfig.default_deposit_percentage || percentageDeposit ==0);		
		return new FinanceSearchTerms(
			(financeTerm && financeTerm > 0 ? financeTerm :financeConfig.default_term),
			(annualMileage && annualMileage > 0 ? annualMileage : financeConfig.default_mileage),
			(depositAmount && depositAmount >=0 ? depositAmount : financeConfig.default_deposit_amount),
			(percentageDeposit && percentageDeposit> 0 ? percentageDeposit : financeConfig.default_deposit_percentage),			 
			parseInt(Page.queryString["minmonthlypayment"]) || null,
			parseInt(Page.queryString["maxmonthlypayment"]) || null,
		    isDefault
		);

			
	}
	

}