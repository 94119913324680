import { Vehicle } from "static/js/app/models/vehicle"
import { VehicleStatus } from "static/js/app/models/__index"

export class HugoPageConfig {
    public vehicle_type: string
    public load_js_page: string
    public running_cost_calculator: boolean = false
    public vehicle?: Vehicle = null
    public branch_id?: number = null
    public title: string
    public form?: string
    public url: string
    public vehicle_status: string
    public number_of_extra_images?: number = null

    constructor(o: HugoPageConfig) {
        if(o == null) { return null }

        Object.assign(this, o);
    }
}
