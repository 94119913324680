import { VehicleImageUrlsDtoToVehicleImageUrls } from "static/js/app/api/dtoMapping";
import { VehicleStatus } from "static/js/app/models/vehicleStatus";

	export class VehicleSearchPerformed {
		constructor(
			public type: string | null = null,
			public vehicleType: string | null = null,
			public vehicleTypeDisplay: string = '',
			public fuelType: string | null = null,
			public fuelTypeDisplay: string = '',
			public manufacturer: string | null = null,
			public manufacturerDisplay: string = '',
			public model: string | null = null,
			public modelDisplay: string = '',
			public bodyType: string | null = null,
			public bodyTypeDisplay: string = '',
			public gearbox: string | null = null,
			public gearboxDisplay: string = '',
			public basicColour: string | null = null,
			public basicColourDisplay: string = '',
			public berth: string = "",
			public doors: string = "",
			public engine: string = "",
			public minEngineSize: string = "",
			public maxEngineSize: string = "",
			public insuranceGroup: string = "",
			public keywords: string = "",
			public minMpg: string = "",
			public maxMpg: string = "",
			public minLength: string = "",
			public maxLength: string = "",
			public minUnladenWeight: string = "",
			public maxUnladenWeight: string = "",
			public year: string = "",
			public vehicles: string = "",
			public branch: string = "",
			public availability: string = 'available',
			public vehicleStatus: VehicleStatus = 'used'
		) { 
			this.bodyTypeDisplay = VehicleSearchPerformed.tweakBodyTypeDisplay(bodyTypeDisplay);
		}

		private static tweakBodyTypeDisplay(bodyTypeDisplay: string) {
			return (bodyTypeDisplay == null) ? null : bodyTypeDisplay.replace("MpvEstate", "MPV and Estate");
		}
	}