import * as Api from "static/js/app/api/endpoints";

export class VehicleComparison {
	public static toggleVehicleCompare(vehicleId: string) {
        const addToComparisons:boolean = !VehicleComparison.isVehicleInComparisonList(vehicleId); // include if not found
        const MAX_COMPARISONS = 10;

        let newVehicleComparisonIds: string[] = VehicleComparison.getVehicleComparisonIds().filter(item => item != vehicleId);

        if(addToComparisons) {   
            newVehicleComparisonIds.push(vehicleId);
        }

        if(newVehicleComparisonIds.length > MAX_COMPARISONS) {
            return `Unable to save vehicle for comparison. A maximum of ${MAX_COMPARISONS} vehicles may be saved for comparison`;
        }

        VehicleComparison.setVehicleComparisonIds(newVehicleComparisonIds);
    }

    public static compareVehicles = async () => { 
        const vehicles = await Api.Vehicles.compare(VehicleComparison.getVehicleComparisonIds());

        // clear out any vehicleids that don't exist so saved count matches expected results
        VehicleComparison.removeNonexistentSavedVehicleIds(vehicles.map((v) => v.id));

        return vehicles;
    }

    private static removeNonexistentSavedVehicleIds(existingVehicleIds: string[]) {
        const savedIds = VehicleComparison.getVehicleComparisonIds();
        const cleanedSavedIds = savedIds.filter(si=>existingVehicleIds.indexOf(si) != -1);

        VehicleComparison.setVehicleComparisonIds(cleanedSavedIds);
    }

    public static getVehiclesCount():number { 
        return VehicleComparison.getVehicleComparisonIds().length;
    }

    public static clearComparisonList() { 
        localStorage.removeItem("vehicleComparisonIds");
    }

    public static isVehicleInComparisonList(vehicleId: string):boolean { 
        return VehicleComparison.getVehicleComparisonIds().indexOf(vehicleId) != -1;
    }

    private static getVehicleComparisonIds():string[] { 
        return JSON.parse(localStorage.vehicleComparisonIds || "[]"); 
    }

    private static setVehicleComparisonIds(vehicleComparisonIds: string[]) {
        localStorage.vehicleComparisonIds = JSON.stringify(vehicleComparisonIds);
    }
}