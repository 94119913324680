import * as Models from  "static/js/app/models/__index";

let awaitedOps:Models.AwaitedOperation[] = [];

export function addCancelation<T>(
    f: ()=>Promise<T>, 
    callGroupOptions: Models.CallGroupOptions = new Models.CallGroupOptions()
) : ()=>Promise<T> 
{
    let newOp:Models.AwaitedOperation = new Models.AwaitedOperation(callGroupOptions.opGrp, Models.Guid.create().toString());
    
    if(callGroupOptions.cancelOpGrpCalls === true) {
        cancelOpGrp(newOp.grp);
    }

    registerCall(newOp); // configures cancellation
    // console.debug(`registered op: ${JSON.stringify(newOp)}`);
    // console.debug(`awaited ops: ${JSON.stringify(awaitedOps)}`);

    return async () => {
        const fResult = await f();
        // console.debug(`f finished: ${JSON.stringify(newOp)}`);

        // console.debug(`awaited ops: ${JSON.stringify(awaitedOps)}`);
        let opIsAwaited:boolean = awaitedOps
            .filter(o => o.id == newOp.id)
            .length > 0;
        
        if(!opIsAwaited) {
            // console.debug(`f not awaited - reject: ${JSON.stringify(newOp)}`);
            // console.debug(`awaited ops: ${JSON.stringify(awaitedOps)}`);
            // console.debug(`operation was cancelled by another request`);
            throw new Error("Operation cancelled");
        }

        // remove this call from the list of awaited calls
        // console.debug(`removing op: ${JSON.stringify(newOp)}`);
        awaitedOps.forEach( 
            (awaitedOp, i) => { 
                if(awaitedOp.id === newOp.id) { 
                    awaitedOps.splice(i, 1); 
                }
            }
        );
        
        // still being waited for
        return fResult;
    };
}

export function cancelOpGrp(opGrp: string)
{
    //console.debug(`cancelling opGrp: ${opGrp}`);

    // remove any with same id (effectively cancels existing calls with same id)
    awaitedOps.forEach( 
        (awaitedOp, i) => { 
            if(awaitedOp.grp === opGrp) { 
                awaitedOps.splice(i, 1);
            }
        }
    );
}

export function registerCall(newOp: Models.AwaitedOperation)
{
    // console.debug(`registering __ ${JSON.stringify(newOp)}`);

    // add to list of awaited operations
    if(awaitedOps.filter(o=>o.grp == newOp.grp && o.id == newOp.id).length === 0) {
        // console.debug(`pushed op: ${JSON.stringify(newOp)}`);
        awaitedOps.push(newOp);
    } else {
        // console.debug(`DID NOT PUSH op: ${JSON.stringify(newOp)}`);
    }
}
