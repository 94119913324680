export class SortTerm {
    constructor(
        public fieldName: string,
        public isDescending: boolean = false
    ) { }
}

export class CombinedSortTerm {
    constructor(
        public sortBy: string,
        public sortOrder: string
    ){}
}