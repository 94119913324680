export class VRMResult {

    constructor(
        public make: string,
        public model: string,
        public derivative: string,
        public body: string,
        public fuel: string,
        public gearbox: string,
        public colour: string,
        public mileage: number,
        public firstRegisteredDate: Date,
        public motExpiry: Date,
        public numberOfOwners: number,
        public isSuccessful: boolean,
        public vrm: string,
        public year: number | null,
        public engineCapacityCC: number | null
        ) { }
}
