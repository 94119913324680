export class QuickReplace {
  public static quickReplace(replacements: {[index: string]: string}, str: string, regexCacher: (regEx?: RegExp) => RegExp | null): string {
    var replacementRegex = null;

    if (typeof (regexCacher) === 'function') {
      // try get cached regex
      replacementRegex = regexCacher();
    }

    if (replacementRegex === null || replacementRegex === undefined) {
      var replacementRegexStr = Object.keys(replacements).join('|');
      replacementRegex = new RegExp(replacementRegexStr, 'g');

      if (typeof (regexCacher) === 'function') {
        // cache the regex
        regexCacher(replacementRegex);
      }
    }

    return str.replace(replacementRegex, function (match) {
      return (replacements[match] !== undefined) ? replacements[match] : match;
    });
  }
}