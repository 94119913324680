import { HugoModulePageContentImageSliderConfig } from "themes/module_page_content_image_slider/static/js/pageContentImageSliderHugoConfig";

export class PageContentImageSlider {
    public static init(config: PageContentImageSliderConfig): PageContentImageSlider {
        return new PageContentImageSlider(config);
    }
    
    private sliderEl: JQuery<HTMLElement>

    private constructor(
        private config: PageContentImageSliderConfig
    ) {
        this.sliderEl = $(config.domSelector);

        if(!this.sliderEl.length) {
            return;
        }

        this.sliderEl.slick(config.settings);
    }
}

export class PageContentImageSliderConfig {
    constructor(
        public domSelector: string = ".slick--page-image",
        public settings?: any
    ) {}

    public static FromHugoPageContentImageSliderConfig(
        hugoSliderConfig: HugoModulePageContentImageSliderConfig
    ): PageContentImageSliderConfig {
        if(hugoSliderConfig == null) {
            return new PageContentImageSliderConfig(); // use defaults
        }

        return new PageContentImageSliderConfig(
            hugoSliderConfig.dom_selector,
            hugoSliderConfig.settings
        );
    }
}