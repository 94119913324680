import DOMPurify from "dompurify";
import { DealerBranchPublicInfo, DepartmentPublicPhoneNumber } from "static/js/app/models/dealerInfo";
import { buildDealerContactPhoneDetails } from "themes/module_vehicle_branch_details/static/js/dealer-contact-phone-template";
import { Page } from "static/js/app/modules/page";
import { Map, Pin } from "themes/module_map/static/js/map";
import { LatLng, LatLngExpression, LatLngBounds } from "leaflet";

export default class VehicleBranchDetails {
	public static init() {
		Page.ready(() => {
			const showFullDealerDetails = $('.l-node-used-vehicle--full__dealer-details').length;
			if (!showFullDealerDetails) {
				return;
			}

			//VehicleBranchDetails.setVehicleBranchInfoAddress(branch);
			//VehicleBranchDetails.setVehicleBranchInfoPhone(branch, buildDealerContactPhoneDetails);
			VehicleBranchDetails.setVehicleBranchInfoDirections();
		});
	}

	private static setVehicleBranchInfoAddress(branch: DealerBranchPublicInfo) {
		const template = document.getElementById("vehicleDetailsAddressTemplate");
		const templateHtml = template.innerHTML;
		const listHtml = templateHtml.replace(/%branch_name%/g, branch.name)
			.replace(/%addr1%/g, (branch.addr1 && branch.addr1 != null) ? branch.addr1 : '')
			.replace(/%addr2%/g, (branch.addr2 && branch.addr2 != null) ? branch.addr2 : '')
			.replace(/%town%/g, (branch.town && branch.town != null) ? branch.town : '')
			.replace(/%county%/g, (branch.county && branch.county != null) ? branch.county : '')
			.replace(/%postcode%/g, (branch.postcode && branch.postcode != null) ? branch.postcode : '')

		// Replace the HTML of #searchResults with final HTML
		document.getElementById("vehicleDetailsAddress").innerHTML = DOMPurify.sanitize(listHtml);
	}

	private static setVehicleBranchInfoDirections() {
		const mapSettings = document.getElementById("branch-map-config");
		if (!mapSettings)
		{
			return;
		}
		const latitude = mapSettings.dataset.latitude;
		const longitude = mapSettings.dataset.longitude;
		
		if(latitude == null || longitude ==null)  {
			return;
		}
		const latLong: LatLngExpression  = [Number(latitude), Number(longitude)];
		const coords = new LatLngBounds(latLong, latLong);
		const branchName = mapSettings.dataset.branchName;
		const pin = new Pin(coords, branchName);

		Map.init("map", pin);
	}

	private static setVehicleBranchInfoPhone(branch: DealerBranchPublicInfo, buildDealerContactPhoneDetails: ((dp: DepartmentPublicPhoneNumber) => string)) {
		const template = document.getElementById("vehicleDetailsPhoneTemplate");
		if (template != null) {
			// Get the contents of the template
			const templateHtml = template.innerHTML;

			let phoneNumbers = branch.departments
				.map(d => d.departmentalPhoneNumbers.map(buildDealerContactPhoneDetails).join(""))
				.join("");

			const listHtml = templateHtml.replace(/%department_phone_numbers%/g, phoneNumbers);

			// Replace the HTML of #searchResults with final HTML
			document.getElementById("vehicleDetailsPhone").innerHTML = DOMPurify.sanitize(listHtml);
		}
	}


}
