import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import * as Models from "static/js/app/models/__index";
import { finance_plugin } from "themes/module_finance_plugin/static/js/finance_plugin";

declare var codeweavers: CodeWeavers.finance.FinanceCalculator;


export class codeweavers_finance_calculator
{
    public static readonly cta_buyitnow = "buy it now";
    public static readonly cta_reserve = "reserve it now";
    public static readonly cta_both = "both";
    private static readonly buyitnow_placeholder = "cta-buy-it-now-btn-placeholder";
    private static readonly reserve_placeholder = "cta-reserve-btn-placeholder";

    
    public static init(config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, financeCta: string = "none", pluginContentDivId: string = "financePluginContent" )
    {        
        if (vehicleSingle.sold ==true)
        {
            return;
        }
        const registrationInfo: CodeWeavers.finance.CodeWeaversRegistration = {
            number: vehicleSingle.vrm,
            date:  StringFormatting.dateAsFormattedString(vehicleSingle.registration_date)
        }
        const defaultMileage: CodeWeavers.finance.CodeWeaversDefaultParametersValue = {
            defaultValue: config.annual_mileage            
        }
        const defaultTerm: CodeWeavers.finance.CodeWeaversDefaultParametersValue = {
            defaultValue: config.term            
        }
        const defaultDeposit: CodeWeavers.finance.CodeWeaversDefaultParametersTypeValue = {
            defaultValue: (config.percentage_deposit !== 0 ? config.percentage_deposit: config.deposit_amount) ,
            defaultType:  (config.percentage_deposit !== 0 ? "Percentage" : "Amount")
        }
        const defaultParameters: CodeWeavers.finance.CodeWeaversDefaultParameters = {
            deposit: defaultDeposit,
            term: defaultTerm,
            annualMileage:  defaultMileage,
            disableCommercialVehicleCalculations: vehicleSingle.vat_exempt
        }
        const vehicleInfo: CodeWeavers.finance.CodeWeaversVehicle = {
                   type: this.mapCWVehicleType(vehicleSingle.vehicle_type),
                   identifier: vehicleSingle.cap_id,
                   identifierType: (vehicleSingle.vehicle_type === "caravan" ? "NoVehicleIdentifier": "CapShortCode"),
                   cashPrice: vehicleSingle.price,
                   isNew: false,
                   imageUrl: vehicleSingle.images.length > 0 ? `https://${window.location.host}/${vehicleSingle.images[0].i850x638}` : "",
                   registration: registrationInfo,
                   //#hack - Caravans don't have a mileage but CW require one - defaulting to 4 to match drupal logic#
                   mileage: (vehicleSingle.vehicle_type === "caravan" ? 4 : vehicleSingle.mileage),
                   linkBackUrl: window.location.href
        };
        const cwfinanceCall: CodeWeavers.finance.CodeWeaversFinanceRequest = {
            vehicle: vehicleInfo,
            pluginContentDivId: pluginContentDivId,
            defaultParameters: defaultParameters,
            onSuccessfulCalculation: (calculations:any) => { 
                
                
                

                
                if (calculations && calculations.length > 0)
                {
                    const quoteReference = calculations[0].Quote.QuoteReference;                                      
                    finance_plugin.setMonthlyPrice(calculations[0].Quote.RegularPayment,document.getElementById("monthlyPaymentLink"));
                    switch(financeCta)
                    {
                        case codeweavers_finance_calculator.cta_buyitnow:
                            codeweavers_finance_calculator.renderCTAButton(codeweavers_finance_calculator.cta_buyitnow,codeweavers_finance_calculator.buyitnow_placeholder,quoteReference );
                            break;                    
                        case codeweavers_finance_calculator.cta_reserve:                    
                            codeweavers_finance_calculator.renderCTAButton(codeweavers_finance_calculator.cta_reserve,codeweavers_finance_calculator.reserve_placeholder,quoteReference );
                            break;                    
                        case codeweavers_finance_calculator.cta_both:                    
                            codeweavers_finance_calculator.renderCTAButton(codeweavers_finance_calculator.cta_buyitnow,codeweavers_finance_calculator.buyitnow_placeholder,quoteReference);
                            codeweavers_finance_calculator.renderCTAButton(codeweavers_finance_calculator.cta_reserve,codeweavers_finance_calculator.reserve_placeholder,quoteReference );
                            break;
                        default:
                            codeweavers_finance_calculator.hideCTAButtons();                           
                    }
                    

                }
                else{
                   
                    codeweavers_finance_calculator.hideCTAButtons(); 
                    finance_plugin.hideMonthlyPrice(document.getElementById("monthlyPaymentLink"));   
                }
            }
        }        
        codeweavers.main(cwfinanceCall);        
    }

    private static renderCTAButton(financeCta: string, placeholderId: string, quoteReference: string)
    {
        const ctaPlaceholder  =  document.getElementById( placeholderId);

        if (ctaPlaceholder && ctaPlaceholder !=null)
        {
            
            const launcherRequest: CodeWeavers.launcher.FinanceLauncherRequest = {
                classList:  [].map.call(ctaPlaceholder.classList, (className:string)=> { return className }),
                pluginContentDivId: ctaPlaceholder.id.replace('-placeholder',''),
                quoteReference: quoteReference,
                innerText: ctaPlaceholder.innerText,
                onEvent: (event:any,data: any) => { ctaPlaceholder.style.display= "none"; }                                                                                               
            };
            (financeCta == codeweavers_finance_calculator.cta_buyitnow ? codeweavers.launcher.checkout.main(launcherRequest) :  codeweavers.launcher.reserve.main(launcherRequest));
        }        
    }

    private static hideCTAButtons()
    {
        const financeCTAs = document.getElementsByClassName("codeweavers-cta");
        [].forEach.call(financeCTAs, (financeCta: HTMLElement) => {
            financeCta.style.display = "none";
        });         
    }

    private static mapCWVehicleType(vehicleType: string): string {
        //allows values car, bike, lcv
        let cleanedType = StringFormatting.coerceNullishToBlank(vehicleType).toLowerCase();
        if (cleanedType === "car" || cleanedType === "bike")
        {
            return cleanedType;
        }
        if (cleanedType ==="commercial" || cleanedType === "van" || cleanedType ==="motorhome" || cleanedType ==="caravan" )
        {
            return "lcv"
        }
        return "";
    }

}
