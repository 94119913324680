// 1800000 => give up completely after about 30 minutes
export async function getJson(url: string, maxWaitMs:number=1800000): Promise<string> {
    return getJsonWithRetries(url, 1, maxWaitMs); 
}

export async function postJson(url: string, body: string, maxWaitMs:number=1800000): Promise<string> {
    return postJsonWithRetries(url, body, 1, maxWaitMs);
}

export async function putFile(url:string, body: File, maxWaitMs:number=1800000): Promise<boolean> {
    return putFileWithRetries(url,body,1,maxWaitMs);
}

function calcBackoffMs(attemptNum: number): number {
    return Math.pow(3, attemptNum) * 100;
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}      

async function getJsonWithRetries(url: string, attempt: number=1, maxWaitMs:number=1800000): Promise<string>
{
    let waitedMs = 0;

    if(attempt > 1) {
        for(let i:number=1; i<attempt; i++) {
            waitedMs += calcBackoffMs(i);
        }
        if(attempt > 6) {
            waitedMs += attempt * 60000;
        }

        if(waitedMs > maxWaitMs) {
            waitedMs = maxWaitMs;
        }
    }

    let xhr = new XMLHttpRequest();
    
    xhr.onerror = function () {
        return Promise.reject(new Error(xhr.responseText));
    };

    xhr.open("GET", url);
    var timeout = (maxWaitMs - waitedMs);
    xhr.timeout = timeout > 100 ? timeout : 100; // wait a minimum of 100 millis
    xhr.send();

    // wait for return
    while(xhr.readyState != 4) {
        await sleep(100);
    }

    if (xhr.status >= 200 && xhr.status < 300) {
        return xhr.responseText;
    } else {
        if( (xhr.status >= 500 && xhr.status < 600) &&
            (waitedMs < maxWaitMs) ) { 
            // wait and retry
            let retryWaitMs = (attempt < 6) ? (Math.pow(3, attempt) * 100) : 60000; // 300, 900, 2700, 8100, 24300, 60000  max retry every 60 seconds

            if(waitedMs + retryWaitMs > maxWaitMs) {
                retryWaitMs = maxWaitMs - waitedMs;
            }

            console.debug(`waiting: ${retryWaitMs}ms before retry`);
            await sleep(retryWaitMs);

            return getJsonWithRetries(url, attempt+1, maxWaitMs);
        } else {
            // give up completely
            console.debug(xhr.responseText);
            return Promise.reject(new Error(xhr.responseText));
        }
    }
}

async function postJsonWithRetries(url: string, body: string, attempt: number=1, maxWaitMs:number=1800000): Promise<string> {
    let waitedMs = 0;

    if(attempt > 1) {
        for(let i:number=1; i<attempt; i++) {
            waitedMs += calcBackoffMs(i);
        }
        if(attempt > 6) {
            waitedMs += attempt * 60000;
        }

        if(waitedMs > maxWaitMs) {
            waitedMs = maxWaitMs;
        }
    }

    let xhr = new XMLHttpRequest();

    xhr.onerror = function () {
        return Promise.reject(new Error(xhr.responseText));
    };

    xhr.open("POST", url);
    var timeout = (maxWaitMs - waitedMs);
    xhr.timeout = timeout > 100 ? timeout : 100; // wait a minimum of 100 millis
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(body);

    // wait for return
    while(xhr.readyState != 4) {
        await sleep(100);
    }

    if (xhr.status >= 200 && xhr.status < 300) {
        return xhr.responseText;
    } else {
        if((xhr.status >= 500 && xhr.status < 600) && waitedMs < maxWaitMs) { 
            // wait and retry
            let retryWaitMs = (attempt < 6) ? (Math.pow(3, attempt) * 100) : 60000; // 300, 900, 2700, 8100, 24300, 60000  max retry every 60 seconds

            if(waitedMs + retryWaitMs > maxWaitMs) {
                retryWaitMs = maxWaitMs - waitedMs;
            }

            console.debug(`waiting: ${retryWaitMs}ms before retry`);
            await sleep(retryWaitMs);

            return postJsonWithRetries(url, body, attempt+1, maxWaitMs);
        } else {
            // give up completely
            console.debug(xhr.responseText);
            return Promise.reject(new Error(xhr.responseText));
        }
    }
}



async function putFileWithRetries(url: string, body: File, attempt: number=1, maxWaitMs:number=1800000): Promise<boolean> {
    let waitedMs = 0;

    if(attempt > 1) {
        for(let i:number=1; i<attempt; i++) {
            waitedMs += calcBackoffMs(i);
        }
        if(attempt > 6) {
            waitedMs += attempt * 60000;
        }

        if(waitedMs > maxWaitMs) {
            waitedMs = maxWaitMs;
        }
    }

    let xhr = new XMLHttpRequest();

    xhr.onerror = function () {
        return Promise.reject(new Error(xhr.responseText));
    };

    xhr.open("PUT", url);
    var timeout = (maxWaitMs - waitedMs);
    xhr.timeout = timeout > 100 ? timeout : 100; // wait a minimum of 100 millis
    xhr.setRequestHeader('Content-Type', body.type);
    xhr.send(body);

    // wait for return
    while(xhr.readyState != 4) {
        await sleep(100);
    }

    if (xhr.status >= 200 && xhr.status < 300) {
        return true;
    } else {
        if(waitedMs < maxWaitMs && (xhr.status >= 500 && xhr.status < 600)) { 
            // wait and retry
            let retryWaitMs = (attempt < 6) ? (Math.pow(3, attempt) * 100) : 60000; // 300, 900, 2700, 8100, 24300, 60000  max retry every 60 seconds

            if(waitedMs + retryWaitMs > maxWaitMs) {
                retryWaitMs = maxWaitMs - waitedMs;
            }

            console.debug(`waiting: ${retryWaitMs}ms before retry`);
            await sleep(retryWaitMs);

            return putFileWithRetries(url, body, attempt+1, maxWaitMs);
        } else {
            // give up completely
            console.debug(xhr.responseText);
            return Promise.reject(new Error(xhr.responseText));
        }
    }
}