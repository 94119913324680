import { HugoPageConfig } from "static/js/app/hugoSettings/hugoPageConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import { Vehicle } from "static/js/app/models/vehicle";

export class VehiclePageConfig extends PageConfig  {
    constructor(
        public vehicleType?: string | null,
        public jsPageId?: string | null,
        public showRunningCostCalculator: boolean = false,
        public vehicle?: Vehicle | null,
        public form?: string,
        public url?: string
    ) {
        super(vehicleType, jsPageId, undefined, undefined, undefined, url);
    }

    public static FromHugoPageConfig(hugoPageConfig: HugoPageConfig): VehiclePageConfig {
        if(hugoPageConfig == null) {
            // use defaults
            return new VehiclePageConfig();                
        }

        return new VehiclePageConfig(
            hugoPageConfig.vehicle_type,
            hugoPageConfig.load_js_page,
            hugoPageConfig.running_cost_calculator,
            hugoPageConfig.vehicle,
            hugoPageConfig.form,
            hugoPageConfig.url
        );
    }
}