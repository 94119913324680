import DOMPurify from "dompurify";
import { Vehicle } from "static/js/app/models/vehicle";
import { RunningCostQuery, RunningCost } from "static/js/app/models/__index";


export default class VehicleRunningCostsCalculator {
    public static init(calculator: HTMLElement) {
        if(calculator == null) {
            return;
        }
        
        //Check if vehicle has the required fields to show RCC
        //ToDo - change template to not render for caravans
         // show calculator
 

        let rccForm = $(calculator).find('form');
        //VehicleRunningCostsCalculator.setupRunningCostForm(rccForm, vehicle.fuel_type, vehicle.mpg, vehicle.tax_rate_12);

       // const runningCostQuery = new RunningCostQuery(vehicle.fuel_type, vehicle.mpg, vehicle.tax_rate_12);
       // const runningCosts = VehicleRunningCostsCalculator.calculateRunningCosts(runningCostQuery);
      //  VehicleRunningCostsCalculator.populateVehicleRunningCosts(
      //      runningCosts
      //  );

        rccForm.on("submit", function (event) {
            event.preventDefault();
            const formRunningCosts = VehicleRunningCostsCalculator.getRunningCostValuesFromForm(rccForm);

            VehicleRunningCostsCalculator.populateVehicleRunningCosts(formRunningCosts);

            return true;
        });
    }

    private static getRunningCostValuesFromForm(rccForm: JQuery<HTMLElement>): RunningCost {
        let fuel_type = rccForm.find('[name="rcc_fuel_type"]').val().toString();
        let annual_tax = Number(rccForm.find('[name="rcc_annual_tax"]').val());
        let annual_mileage = Number(rccForm.find('[name="rcc_annual_mileage"]').val());
        let mpg = Number(rccForm.find('[name="rcc_mpg"]').val());

        if(fuel_type != "petrol" && fuel_type != "diesel") {
            return new RunningCost(fuel_type, mpg, annual_tax);
        }
        
        const query = new RunningCostQuery(
            fuel_type,
            mpg,
            annual_tax,
            annual_mileage
        );

        return VehicleRunningCostsCalculator.calculateRunningCosts(query);
    }

    private static toCurrencyString = (input: number, currency: string) => (currency + Number(input).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }))

    private static populateVehicleRunningCosts(runningCost: RunningCost) {
        let template = document.getElementById("runningCostResultsTemplate");
        // Get the contents of the template
        let templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        let rccHtml = "";

        rccHtml += templateHtml
            .replace(/%rcc_annual_fuel_cost%/g, (runningCost.annual_mileage > 0 ? VehicleRunningCostsCalculator.toCurrencyString(runningCost.annual_fuel_cost, '£') : "£ -"))
            .replace(/%rcc_monthly_fuel_cost%/g, (runningCost.annual_mileage > 0 ? VehicleRunningCostsCalculator.toCurrencyString(runningCost.monthly_fuel_cost, '£') : "£ -"))
            .replace(/%rcc_annual_tax_cost%/g, (runningCost.annual_mileage > 0 ? VehicleRunningCostsCalculator.toCurrencyString(runningCost.annual_tax_cost, '£') : "£ -"))
            .replace(/%rcc_monthly_tax_cost%/g, (runningCost.annual_mileage > 0 ? VehicleRunningCostsCalculator.toCurrencyString(runningCost.monthly_tax_cost, '£') : "£ -"))
            .replace(/%rcc_fuel_type%/g, runningCost.fuel_type)
            .replace(/%rcc_fuel_ppl%/g, VehicleRunningCostsCalculator.toCurrencyString(runningCost.fuelcost_per_litre / 100, '£'))
            .replace(/%rcc_mpg%/g, Number(runningCost.mpg).toLocaleString(undefined, { maximumFractionDigits: 2 }))

        document.getElementById("runningCostsResults").innerHTML = DOMPurify.sanitize(rccHtml);
    }

    public static calculateRunningCosts(query: RunningCostQuery) {

        const gallonToLitres = 0.219969248; //constant value for conversion from UK gallon to litres
        const monthlyTaxPremium = 1.05; // Paying Monthly is 5% more
        //Calculations
        let fuelppl = this.GetFuelPrice(query.fuel_type);

        let monthlyTax = ((query.annual_tax_cost * monthlyTaxPremium) / 12); //monthly tax in pounts        

        //only calculate fuel for petrol or diesel cars that have an MPG value

        if ((query.mpg == null)
            || query.mpg <= 0
        ) {
            return new RunningCost(
                query.fuel_type,
                0,
                query.annual_tax_cost
            )
        }
        let annualFuelCost = (((query.annual_mileage / (query.mpg * gallonToLitres)) * fuelppl) / 100);
        let monthlyFuelCost = annualFuelCost / 12;

        return new RunningCost(
            query.fuel_type,
            query.mpg,
            query.annual_tax_cost,
            fuelppl,
            query.annual_mileage,
            monthlyTax,
            monthlyFuelCost,
            annualFuelCost)
    }

    private static GetFuelPrice(fuel_type: string) {
        // @ts-ignore 
        // global defined in https://snapi-js-lib.s3-eu-west-1.amazonaws.com/SNAPI-RCC/rcc_fuelPrices.js
        return (window.rcc_fuelPrice != null) ? window.rcc_fuelPrice[fuel_type] : 0;
    }
}