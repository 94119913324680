export class LocalCache {

   
    
public static setWithExpiry(key: string, value: string, ttlSeconds: number = 1440000) {
	if (key !=null && value != null)
    {
        const now = new Date()
	    const item:CacheObject = {
		    value: value,
		    expiry: now.getTime() + (ttlSeconds*1000),
    	}
	    localStorage.setItem(key, JSON.stringify(item))
    }
}

public static getWithExpiry(key: string) : string {
	const itemStr = localStorage.getItem(key)

	if (itemStr == null) {
		return null
	}
    try {
	const item = JSON.parse(itemStr) as CacheObject
    const now = new Date()
	
	if (now.getTime() > item.expiry) {		
		localStorage.removeItem(key)
		return null
	}
	return item.value
    }
    catch{
        return null;
    }    	
}
}

interface CacheObject {
    expiry: number
    value: string;
}